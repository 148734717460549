const KInitWasm_Ok = 0;

const KError_None = 0;
const KError_InitDecoder = 1;
const KError_FFmpegDecodec = 2;
const KError_AllocAVFrame = 3;
const kError_InvalidParam = 4;
const kError_InvalidData = 5;
const kError_InvalidFormat = 6;

//Decoder request.
const kInitDecoderReq = 0;
const kUninitDecoderReq = 1;
const kOpenDecoderReq = 2;
const kCloseDecoderReq = 3;
const kFeedDataReq = 4;
const kStartDecodingReq = 5;
const kPauseDecodingReq = 6;

//Decoder response.
const kInitDecoderRsp = 0;
const kUninitDecoderRsp = 1;
const kOpenDecoderRsp = 2;
const kCloseDecoderRsp = 3;
const kVideoFrame = 4;
const kAudioFrame = 5;
const kStartDecodingRsp = 6;
const kPauseDecodingRsp = 7;
const kDecodeFinishedEvt = 8;

// websocket request
const KWebSocket_InitReq = 9;
const KWebSocket_ConnectReq = 10;

// websocket response
const KWebSocket_InitRsp = 9;
const KWebSocket_ConnectRsq = 10;

const KWebSocket_StreamStart = 11;
const KWebSocket_StreamStop = 12;
const KWebSocket_Open = 13;
const KWebSocket_Error = 14;
const KWebSocket_Close = 15;
const KWebSocket_Message = 16;
const KWebSocket_OpenAudio = 17;

// websocket send out data
const KWebSocket_sendG726EncData = 18;
const kAudioPCMData = 19;
const kAudioEncFrame = 20;

const KWebSocket_playControl = 21; //播放控制指令

const g_cnfServerSSLPort = 36301;
const cnfAudioSampleRate = 8000; //采样率
const cnfAudioSampleBits = 16; // 深度
const cnfAudioSamplePCMLen = 640; // pcm frame len

function Logger(module) {
  this.module = module;
}

Logger.prototype.log = function (line) {
  //console.log(this.currentTimeStr() + this.module + " " + line);
};

Logger.prototype.logError = function (line) {
  //console.log(this.currentTimeStr() + this.module + " ERROR " + line);
};

Logger.prototype.logInfo = function (line) {
  //console.log(this.currentTimeStr() + this.module + " INFO " + line);
};

Logger.prototype.logDebug = function (line) {
  // console.log(this.currentTimeStr() + this.module + " DEBUG " + line);
};

Logger.prototype.currentTimeStr = function () {
  var now = new Date(Date.now());
  var year = now.getFullYear();
  var month = now.getMonth() + 1;
  var day = now.getDate();
  var hour = now.getHours();
  var min = now.getMinutes();
  var sec = now.getSeconds();
  // var ms = now.getMilliseconds();
  return (
    year + '-' + month + '-' + day + ' ' + hour + ':' + min + ':' + sec + ' '
  );
};

function Texture(gl) {
  this.gl = gl;
  this.texture = gl.createTexture();
  gl.bindTexture(gl.TEXTURE_2D, this.texture);

  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);

  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
}

Texture.prototype.bind = function (n, program, name) {
  var gl = this.gl;
  gl.activeTexture([gl.TEXTURE0, gl.TEXTURE1, gl.TEXTURE2][n]);
  gl.bindTexture(gl.TEXTURE_2D, this.texture);
  gl.uniform1i(gl.getUniformLocation(program, name), n);
};

Texture.prototype.fill = function (width, height, data) {
  var gl = this.gl;
  gl.bindTexture(gl.TEXTURE_2D, this.texture);
  gl.texImage2D(
    gl.TEXTURE_2D,
    0,
    gl.LUMINANCE,
    width,
    height,
    0,
    gl.LUMINANCE,
    gl.UNSIGNED_BYTE,
    data,
  );
};

function WebGLPlayer(canvas) {
  this.canvas = canvas;
  this.gl =
    canvas.getContext('webgl', { preserveDrawingBuffer: true }) ||
    canvas.getContext('experimental-webgl', { preserveDrawingBuffer: true });
  this.initGL();
}

WebGLPlayer.prototype.initGL = function () {
  if (!this.gl) {
    console.log('[ER] WebGL not supported.');
    return;
  }

  var gl = this.gl;
  gl.pixelStorei(gl.UNPACK_ALIGNMENT, 1);
  var program = gl.createProgram();
  var vertexShaderSource = [
    'attribute highp vec4 aVertexPosition;',
    'attribute vec2 aTextureCoord;',
    'varying highp vec2 vTextureCoord;',
    'void main(void) {',
    ' gl_Position = aVertexPosition;',
    ' vTextureCoord = aTextureCoord;',
    '}',
  ].join('\n');
  var vertexShader = gl.createShader(gl.VERTEX_SHADER);
  gl.shaderSource(vertexShader, vertexShaderSource);
  gl.compileShader(vertexShader);
  var fragmentShaderSource = [
    'precision highp float;',
    'varying lowp vec2 vTextureCoord;',
    'uniform sampler2D YTexture;',
    'uniform sampler2D UTexture;',
    'uniform sampler2D VTexture;',
    'const mat4 YUV2RGB = mat4',
    '(',
    ' 1.1643828125, 0, 1.59602734375, -.87078515625,',
    ' 1.1643828125, -.39176171875, -.81296875, .52959375,',
    ' 1.1643828125, 2.017234375, 0, -1.081390625,',
    ' 0, 0, 0, 1',
    ');',
    'void main(void) {',
    ' gl_FragColor = vec4( texture2D(YTexture, vTextureCoord).x, texture2D(UTexture, vTextureCoord).x, texture2D(VTexture, vTextureCoord).x, 1) * YUV2RGB;',
    '}',
  ].join('\n');

  var fragmentShader = gl.createShader(gl.FRAGMENT_SHADER);
  gl.shaderSource(fragmentShader, fragmentShaderSource);
  gl.compileShader(fragmentShader);
  gl.attachShader(program, vertexShader);
  gl.attachShader(program, fragmentShader);
  gl.linkProgram(program);
  gl.useProgram(program);
  if (!gl.getProgramParameter(program, gl.LINK_STATUS)) {
    console.log('[ER] Shader link failed.');
  }
  var vertexPositionAttribute = gl.getAttribLocation(
    program,
    'aVertexPosition',
  );
  gl.enableVertexAttribArray(vertexPositionAttribute);
  var textureCoordAttribute = gl.getAttribLocation(program, 'aTextureCoord');
  gl.enableVertexAttribArray(textureCoordAttribute);

  var verticesBuffer = gl.createBuffer();
  gl.bindBuffer(gl.ARRAY_BUFFER, verticesBuffer);
  gl.bufferData(
    gl.ARRAY_BUFFER,
    new Float32Array([
      1.0, 1.0, 0.0, -1.0, 1.0, 0.0, 1.0, -1.0, 0.0, -1.0, -1.0, 0.0,
    ]),
    gl.STATIC_DRAW,
  );
  gl.vertexAttribPointer(vertexPositionAttribute, 3, gl.FLOAT, false, 0, 0);
  var texCoordBuffer = gl.createBuffer();
  gl.bindBuffer(gl.ARRAY_BUFFER, texCoordBuffer);
  gl.bufferData(
    gl.ARRAY_BUFFER,
    new Float32Array([1.0, 0.0, 0.0, 0.0, 1.0, 1.0, 0.0, 1.0]),
    gl.STATIC_DRAW,
  );
  gl.vertexAttribPointer(textureCoordAttribute, 2, gl.FLOAT, false, 0, 0);

  gl.y = new Texture(gl);
  gl.u = new Texture(gl);
  gl.v = new Texture(gl);
  gl.y.bind(0, program, 'YTexture');
  gl.u.bind(1, program, 'UTexture');
  gl.v.bind(2, program, 'VTexture');
};

WebGLPlayer.prototype.renderFrame = function (
  videoFrame,
  width,
  height,
  uOffset,
  vOffset,
) {
  if (!this.gl) {
    console.log('[ER] Render frame failed due to WebGL not supported.');
    return;
  }

  var gl = this.gl;
  gl.viewport(0, 0, gl.canvas.width, gl.canvas.height);
  gl.clearColor(0.0, 0.0, 0.0, 0.0);
  gl.clear(gl.COLOR_BUFFER_BIT);

  gl.y.fill(width, height, videoFrame.subarray(0, uOffset));
  gl.u.fill(
    width >> 1,
    height >> 1,
    videoFrame.subarray(uOffset, uOffset + vOffset),
  );
  gl.v.fill(
    width >> 1,
    height >> 1,
    videoFrame.subarray(uOffset + vOffset, videoFrame.length),
  );

  gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4);
};

WebGLPlayer.prototype.fullscreen = function () {
  var canvas = this.canvas;
  if (canvas.RequestFullScreen) {
    canvas.RequestFullScreen();
  } else if (canvas.webkitRequestFullScreen) {
    canvas.webkitRequestFullScreen();
  } else if (canvas.mozRequestFullScreen) {
    canvas.mozRequestFullScreen();
  } else if (canvas.msRequestFullscreen) {
    canvas.msRequestFullscreen();
  } else {
    alert("This browser doesn't supporter fullscreen");
  }
};

WebGLPlayer.prototype.exitfullscreen = function () {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen();
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  } else if (document.msExitFullscreen) {
    document.msExitFullscreen();
  } else {
    alert("Exit fullscreen doesn't work");
  }
};

WebGLPlayer.prototype.unInit = function () {
  var gl = this.gl;
  gl.viewport(0, 0, gl.canvas.width, gl.canvas.height);
  gl.clearColor(0.0, 0.0, 0.0, 0.0);
  gl.clear(gl.COLOR_BUFFER_BIT);
  this.gl = null;
};

/**
 * element id
 * example : <div id="player"></div>
 * id = player
 * var player= new VssPlayer('#player')
 * @param {*} ele
 */

const playerStateIdle = 0;
const playerStatePlaying = 1;
const playerStatePausing = 2;

function VssPlayer(ele, callback) {
  this.ele = ele;
  this.playerState = playerStateIdle;
  this.session = -1;
  this.deviceID = '';
  this.callback = callback;
}

VssPlayer.prototype = {
  initCanvas: function () {
    var _div = document.querySelector(this.ele);

    if (this.canvasElement) {
      _div.removeChild(this.canvasElement);
    }
    this.canvasElement = document.createElement('canvas');
    this.canvasElement.height = _div.clientHeight;
    this.canvasElement.width = _div.clientWidth;
    _div.append(this.canvasElement);
  },
  /**
   * Live
   * @param {*} ip  Streaming Server Ip
   * @param {*} prot  Streaming Server Prot
   * @param {*} deviceID  deviceID
   * @param {*} channel   channel
   * @param {*} stream  0 Sub stream / 1 Main stream
   * @param {*} openAudio   Whether to turn on the sound, true/false default : false
   * @param {*} bufferTimeMs   Cache duration
   * @param {*} userName  Current login username
   * @returns
   */
  live: function (
    ip,
    prot,
    deviceID,
    channel,
    stream,
    openAudio = false,
    bufferTimeMs = 2500,
    userName = 'admin',
  ) {
    if (this.playerState === playerStatePlaying) {
      return;
    }
    this.initCanvas();
    this.initSocketWorker();
    this.webglPlayer = new WebGLPlayer(this.canvasElement, {
      preserveDrawingBuffer: false,
    });
    this.session = +new Date();
    this.deviceID = deviceID;
    var playload = {
      action: '3000',
      payload: {
        sessionID: this.session,
        deviceID: deviceID,
        channel: channel,
        stream: stream,
        bufferTimeMs: bufferTimeMs,
        username: userName,
        bussType: '1',
      },
    };
    this.wsConnect(
      this.wsUrl(ip, prot, 'stream'),
      JSON.stringify(playload),
      this.session,
    );
    this.playerState = playerStatePlaying;
    this.openAudio(openAudio);
    this.callback && this.callback({ status: 'loading' });
  },
  /**
   *
   * @param {*} ip  Server Ip
   * @param {*} prot  Server Prot
   * @param {*} deviceID  deviceID
   * @param {*} file   File
   * @param {*} openAudio  Whether to turn on the sound, true/false default : false
   * @returns
   */
  alarmFile: function (ip, prot, deviceID, file, openAudio = false) {
    if (this.playerState === playerStatePlaying) {
      return;
    }
    this.initCanvas();
    this.initSocketWorker();
    this.webglPlayer = new WebGLPlayer(this.canvasElement, {
      preserveDrawingBuffer: false,
    });
    this.session = +new Date();
    this.deviceID = deviceID;
    var playload = {
      action: '3003',
      payload: {
        sessionID: this.session,
        deviceID: deviceID,
        startTime: '',
        stopTime: '',
        offset: '0',
        fileName: file,
        actionType: '1',
      },
    };
    this.wsConnect(
      this.wsUrl(ip, prot, 'alarmserver'),
      JSON.stringify(playload),
      {
        deviceId: this.deviceID,
        sessionId: this.session,
      },
    );
    this.playerState = playerStatePlaying;
    this.openAudio(openAudio);
    this.callback && this.callback({ status: 'loading' });
  },

  /**
   *
   * @param {*} ip  Server Ip
   * @param {*} prot  Server Prot
   * @param {*} deviceID  deviceID
   * @param {*} file  File
   * @param {*} openAudio  Whether to turn on the sound, true/false default : false
   * @returns
   */
  cloudVideoFile: function (ip, prot, deviceID, file, openAudio = false) {
    if (this.playerState === playerStatePlaying) {
      return;
    }
    this.initCanvas();
    this.initSocketWorker();
    this.webglPlayer = new WebGLPlayer(this.canvasElement, {
      preserveDrawingBuffer: false,
    });
    this.session = +new Date();
    this.deviceID = deviceID;
    var playload = {
      action: '3003',
      payload: {
        sessionID: this.session,
        deviceID: deviceID,
        startTime: '',
        stopTime: '',
        offset: '0',
        fileName: file,
        actionType: '1',
      },
    };
    this.wsConnect(
      this.wsUrl(ip, prot, 'alarmserver'),
      JSON.stringify(playload),
      {
        deviceId: this.deviceID,
        sessionId: this.session,
      },
    );
    this.playerState = playerStatePlaying;
    this.openAudio(openAudio);
    this.callback && this.callback({ status: 'loading' });
  },
  /**
   *
   * @param {*} ip  Server Ip
   * @param {*} prot  Server Prot
   * @param {*} deviceID  deviceID
   * @param {*} file  File
   * @param {*} openAudio  Whether to turn on the sound, true/false default : false
   * @returns
   */
  autoDownloadServerFile: function (
    ip,
    prot,
    deviceID,
    file,
    openAudio = false,
  ) {
    if (this.playerState === playerStatePlaying) {
      return;
    }
    this.initCanvas();
    this.initSocketWorker();
    this.webglPlayer = new WebGLPlayer(this.canvasElement, {
      preserveDrawingBuffer: false,
    });
    this.session = +new Date();
    this.deviceID = deviceID;
    var playload = {
      action: '3003',
      payload: {
        sessionID: this.session,
        deviceID: this.deviceID,
        startTime: '',
        stopTime: '',
        offset: '0',
        fileName: file,
        actionType: '1',
      },
    };
    this.wsConnect(
      this.wsUrl(ip, prot, 'autoserver'),
      JSON.stringify(playload),
      {
        deviceId: this.deviceID,
        sessionId: this.session,
      },
    );
    this.playerState = playerStatePlaying;
    this.openAudio(openAudio);
    this.callback && this.callback({ status: 'loading' });
  },

  /**
   *
   * @param {*} ip  Server Ip
   * @param {*} prot  Server Prot
   * @param {*} deviceID   deviceID
   * @param {*} channel  channel
   * @param {*} start_date_time  start datetime
   * @param {*} end_date_time  end datetime
   * @param {*} openAudio  Whether to turn on the sound, true/false default : false
   * @param {*} userName  Current login username
   * @returns
   */
  playback: function (
    ip,
    prot,
    deviceID,
    channel,
    start_date_time,
    end_date_time,
    openAudio = false,
    userName = 'admin',
  ) {
    if (this.playerState === playerStatePlaying) {
      return;
    }
    this.initCanvas();
    this.initSocketWorker();
    this.webglPlayer = new WebGLPlayer(this.canvasElement, {
      preserveDrawingBuffer: false,
    });
    this.session = +new Date();
    this.deviceID = deviceID;
    var playload = {
      action: '3003',
      payload: {
        sessionID: this.session,
        deviceID: deviceID,
        channel: channel,
        startTime: start_date_time,
        stopTime: end_date_time,
        offset: '0',
        fileName: '',
        actionType: '1',
        username: userName,
        bussType: '4',
      },
    };
    this.wsConnect(this.wsUrl(ip, prot, 'stream'), JSON.stringify(playload), {
      deviceId: this.deviceID,
      sessionId: this.session,
      channel: channel,
    });
    this.playerState = playerStatePlaying;
    this.openAudio(openAudio);
    this.callback && this.callback({ status: 'loading' });
  },

  /**
   *
   * @param {*} speed 0,  1 ,2 , 4 ,8 ,16
   */
  playbackSpeed: function (speed) {
    if (this.playerState !== playerStatePlaying) {
      return;
    }
    const objData = {
      t: KWebSocket_playControl,
      d: 3,
      o: speed,
    };
    this.socketWorker.postMessage(objData);
    this.callback && this.callback({ status: 'speed ' + speed });
  },
  /**
   *
   * @param {*} openAudio true : On   false : Off
   */
  openAudio: function (openAudio) {
    if (openAudio == true) {
      this.pcmPlayer = new PCMPlayer({
        encoding: '16bitInt',
        channels: 1,
        sampleRate: 8000,
        flushingTime: 200,
      });
      this.callback && this.callback({ status: 'audio on' });
    } else {
      this.pcmPlayer = null;
      this.callback && this.callback({ status: 'audio off' });
    }
  },
  resumePlay: function () {
    if (this.playerState == playerStatePausing) {
      const objData = {
        t: KWebSocket_playControl,
        d: 2,
        o: 0,
      };
      this.socketWorker.postMessage(objData);
      this.playerState = playerStatePlaying;
      this.callback && this.callback({ status: 'playing' });
    }
  },
  pause: function () {
    if (this.playerState == playerStatePlaying) {
      const objData = {
        t: KWebSocket_playControl,
        d: 1,
        o: 0,
      };
      this.socketWorker.postMessage(objData);

      this.playerState = playerStatePausing;
      this.callback && this.callback({ status: 'pause' });
    }
  },
  stop: function () {
    if (this.playerState != playerStateIdle) {
      this.playerState = playerStateIdle;
      var objData = {
        t: KWebSocket_StreamStop,
      };
      this.socketWorker.postMessage(objData);
      this.webglPlayer.unInit();
      this.webglPlayer = null;
      this.callback && this.callback({ status: 'stop' });
    }
    this.onStreamStop();
    this.videoWidth = undefined;
    var _div = document.querySelector(this.ele);
    if (this.canvasElement) {
      _div.removeChild(this.canvasElement);
    }
    this.canvasElement = null;
    this.playerState = playerStateIdle;
  },
  onStreamStop: function (evt) {
    this.socketWorker.terminate();
  },
  onVideoFrame: function (frame) {
    if (!this.videoWidth) {
      this.videoWidth = frame.w;
      this.videoHeight = frame.h;
      this.yLength = this.videoWidth * this.videoHeight;
      this.uvLength = (this.videoWidth / 2) * (this.videoHeight / 2);
      this.callback && this.callback({ status: 'playing' });
    }

    var data = new Uint8Array(frame.d);
    if (this.webglPlayer)
      this.webglPlayer.renderFrame(
        data,
        this.videoWidth,
        this.videoHeight,
        this.yLength,
        this.uvLength,
      );
    this.currentTime = frame.ts;
  },

  onAudioFrame: function (frame) {
    if (this.pcmPlayer) {
      if (this.pcmSampleRate == 24000) {
        this.pcmPlayer.feed(this.Pcm8t24(frame.d));
        return true;
      }
      this.pcmPlayer.feed(frame.d);
    }
  },

  wsUrl: function (ip, prot, streamServerType) {
    return `wss://${ip}:${prot}/${streamServerType}?ipaddr=127.0.0.1`;
    // TODO: will change
    // let _proc = window.location.protocol.toLowerCase();
    // if (_proc == "http:") {
    //     return `ws://${ip}:${prot}/${streamServerType}`;
    // }
    // if (ip == window.location.hostname) {
    //     return `wss://${ip}:${prot}/${streamServerType}?ipaddr=127.0.0.1`;
    // }
    // return `wss://${window.location.hostname}:${prot}/${streamServerType}?ipaddr=${ip}`;
  },

  initSocketWorker: function () {
    var self = this;
    this.socketWorker = new Worker('./assets/scripts/hwwebsocket.js');

    this.socketWorker.onmessage = function (evt) {
      var objData = evt.data;
      switch (objData.t) {
        case kInitDecoderRsp:
          self.onStreamStart(objData);
          break;
        case KWebSocket_Close:
          self.onStreamStop();
          break;
        case KWebSocket_Message:
          self.onStream(objData.d);
          break;
        case KWebSocket_Open:
          break;
        case kVideoFrame:
          self.onVideoFrame(objData);
          break;
        case kAudioFrame:
          self.onAudioFrame(objData);
          break;
      }
    };
  },
  wsConnect: function (url, playload, session) {
    var objData = {
      t: KWebSocket_ConnectReq,
      u: url,
      s: playload,
      ob: session,
    };
    this.socketWorker.postMessage(objData);
  },
};

function PCMPlayer(option) {
  this.init(option);
}

PCMPlayer.prototype.init = function (option) {
  var defaults = {
    encoding: '16bitInt',
    channels: 1,
    sampleRate: 8000,
    flushingTime: 1000,
  };
  this.option = Object.assign({}, defaults, option);
  this.samples = new Float32Array();
  //this.resampler = new Resampler(this.option.sampleRate, 44100, 1, 4096);
  this.flush = this.flush.bind(this);
  this.interval = setInterval(this.flush, this.option.flushingTime);
  this.maxValue = this.getMaxValue();
  this.typedArray = this.getTypedArray();
  this.createContext();
};

PCMPlayer.prototype.getMaxValue = function () {
  var encodings = {
    '8bitInt': 128,
    '16bitInt': 32768,
    '32bitInt': 2147483648,
    '32bitFloat': 1,
  };

  return encodings[this.option.encoding]
    ? encodings[this.option.encoding]
    : encodings['16bitInt'];
};

PCMPlayer.prototype.getTypedArray = function () {
  var typedArrays = {
    '8bitInt': Int8Array,
    '16bitInt': Int16Array,
    '32bitInt': Int32Array,
    '32bitFloat': Float32Array,
  };

  return typedArrays[this.option.encoding]
    ? typedArrays[this.option.encoding]
    : typedArrays['16bitInt'];
};

PCMPlayer.prototype.createContext = function () {
  this.audioCtx = new (window.AudioContext || window.webkitAudioContext)();
  this.gainNode = this.audioCtx.createGain();
  this.gainNode.gain.value = 1;
  this.gainNode.connect(this.audioCtx.destination);
  this.startTime = this.audioCtx.currentTime;
};

PCMPlayer.prototype.isTypedArray = function (data) {
  return (
    data.byteLength && data.buffer && data.buffer.constructor == ArrayBuffer
  );
};

PCMPlayer.prototype.feed = function (data) {
  if (!this.isTypedArray(data)) return;

  //data = this.resampler.resample(data);

  data = this.getFormatedValue(data);
  var tmp = new Float32Array(this.samples.length + data.length);
  tmp.set(this.samples, 0);
  tmp.set(data, this.samples.length);
  this.samples = tmp;
};

PCMPlayer.prototype.getFormatedValue = function (data) {
  var data = new this.typedArray(data.buffer),
    float32 = new Float32Array(data.length),
    i;

  for (i = 0; i < data.length; i++) {
    float32[i] = data[i] / this.maxValue;
  }
  return float32;
};

PCMPlayer.prototype.volume = function (volume) {
  this.gainNode.gain.value = volume;
};

PCMPlayer.prototype.destroy = function () {
  if (this.interval) {
    clearInterval(this.interval);
  }
  this.samples = null;
  this.audioCtx.close();
  this.audioCtx = null;
};

PCMPlayer.prototype.flush = function () {
  if (!this.samples.length) return;

  var bufferSource = this.audioCtx.createBufferSource(),
    length = this.samples.length / this.option.channels,
    audioBuffer = this.audioCtx.createBuffer(
      this.option.channels,
      length,
      this.option.sampleRate,
    ),
    audioData,
    channel,
    offset,
    i,
    decrement;

  for (channel = 0; channel < this.option.channels; channel++) {
    audioData = audioBuffer.getChannelData(channel);
    offset = channel;
    decrement = 50;
    for (i = 0; i < length; i++) {
      audioData[i] = this.samples[offset];
      /* fadein */
      if (i < 50) {
        audioData[i] = (audioData[i] * i) / 50;
      }
      /* fadeout*/
      if (i >= length - 51) {
        audioData[i] = (audioData[i] * decrement--) / 50;
      }
      offset += this.option.channels;
    }
  }

  if (this.startTime < this.audioCtx.currentTime) {
    this.startTime = this.audioCtx.currentTime;
  }

  //console.log('start vs current '+this.startTime+' vs '+this.audioCtx.currentTime+' duration: '+audioBuffer.duration);
  bufferSource.buffer = audioBuffer;
  bufferSource.connect(this.gainNode);
  bufferSource.start(this.startTime);
  this.startTime += audioBuffer.duration;
  this.samples = new Float32Array();
};

PCMPlayer.prototype.getTimestamp = function () {
  if (this.audioCtx) {
    return this.audioCtx.currentTime;
  } else {
    return 0;
  }
};

PCMPlayer.prototype.play = function (data) {
  if (!this.isTypedArray(data)) {
    return;
  }

  data = this.getFormatedValue(data);
  if (!data.length) {
    return;
  }

  var bufferSource = this.audioCtx.createBufferSource(),
    length = data.length / this.option.channels,
    audioBuffer = this.audioCtx.createBuffer(
      this.option.channels,
      length,
      this.option.sampleRate,
    ),
    audioData,
    channel,
    offset,
    i,
    decrement;

  for (channel = 0; channel < this.option.channels; channel++) {
    audioData = audioBuffer.getChannelData(channel);
    offset = channel;
    decrement = 50;
    for (i = 0; i < length; i++) {
      audioData[i] = data[offset];
      /* fadein */
      if (i < 50) {
        audioData[i] = (audioData[i] * i) / 50;
      }
      /* fadeout*/
      if (i >= length - 51) {
        audioData[i] = (audioData[i] * decrement--) / 50;
      }
      offset += this.option.channels;
    }
  }

  if (this.startTime < this.audioCtx.currentTime) {
    this.startTime = this.audioCtx.currentTime;
  }
  //console.log('start vs current '+this.startTime+' vs '+this.audioCtx.currentTime+' duration: '+audioBuffer.duration);
  bufferSource.buffer = audioBuffer;
  bufferSource.connect(this.gainNode);
  bufferSource.start(this.startTime);
  this.startTime += audioBuffer.duration;
};

PCMPlayer.prototype.pause = function () {
  if (this.audioCtx.state === 'running') {
    this.audioCtx.suspend();
  }
};

PCMPlayer.prototype.resume = function () {
  if (this.audioCtx.state === 'suspended') {
    this.audioCtx.resume();
  }
};
